import React from "react"
import { Link } from "gatsby"
import "./banner.scss"

const Banner = ({ children, style }) => {
  return (
    <div className="bannerOn d-flex justify-content-around">
      <div className="d-flex align-items-center ">
        <p style={{ fontWeight: "800" }}>
        Multi-vendor marketplaces: the next generation of retail ecommerce <span style={{fontWeight:'400'}}>by Raconteur</span>
        </p>
        <Link to="https://www.raconteur.net/insights/multi-vendor-marketplaces-the-next-generation-of-retail-ecommerce">
        Read Business Insights Report
        </Link>
      </div>
    </div>
  )
}

export default Banner
